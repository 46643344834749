.post {
    .signup,
    .login {
        font-size: 16px;
    }

    .header {
        position: fixed;
        top: 0;
        width: 100%;
        background: white;
        z-index: 1;
    }

    .content {
        margin-top: 120px;
    }

    .signup {
        border: 1px solid $primary-color;
    }

    .nav {
        a {
            color: $primary-color;
        }
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
    }

    h2 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 0;
    }

    h3 {
        font-size: 18px;
    }

    .button-link {
        margin-top: 8px;
        display: inline-block;
        width: inherit;
    }

    .button {
        width: 50%;
        background: $secondary-color;
        font-weight: 800;
        padding: 15px 20px;
    }

    .benefits {
        border-radius: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 2rem 2.375rem;
        border: 1px solid #eef3f5;
    }

    li {
        margin-bottom: 30px;
        padding-left: 12px;

        &::marker {
            content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PgogICAgICAgIDwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgCiAgICAgICAgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+IDxzdmcgc3R5bGU9ImNvbG9yOiByZ2IoNjUsIDIwNSwgMTQwKTsiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSJjdXJyZW50Q29sb3IiIGNsYXNzPSJiaSBiaS1jaGVjay1jaXJjbGUiIHZpZXdCb3g9IjAgMCAxNiAxNiI+IDxwYXRoIGQ9Ik04IDE1QTcgNyAwIDEgMSA4IDFhNyA3IDAgMCAxIDAgMTR6bTAgMUE4IDggMCAxIDAgOCAwYTggOCAwIDAgMCAwIDE2eiIgZmlsbD0iIzQxY2Q4YyI+PC9wYXRoPiA8cGF0aCBkPSJNMTAuOTcgNC45N2EuMjM1LjIzNSAwIDAgMC0uMDIuMDIyTDcuNDc3IDkuNDE3IDUuMzg0IDcuMzIzYS43NS43NSAwIDAgMC0xLjA2IDEuMDZMNi45NyAxMS4wM2EuNzUuNzUgMCAwIDAgMS4wNzktLjAybDMuOTkyLTQuOTlhLjc1Ljc1IDAgMCAwLTEuMDcxLTEuMDV6IiBmaWxsPSIjNDFjZDhjIj48L3BhdGg+IDwvc3ZnPiA=');
            margin-right: 10px;
        }
    }

    .blog-img {
        width: 100%;
    }
}