.nav {
    margin-top: 25px;
    a {
        color: white;
    }
    .signup {
        border: 1px solid white;
        background: 0;
        border-radius: 20px;
        font-size: 12px;
        padding: 10px;

        &:hover {
            color: $primary-color;
            background: white;
        }
    }
}