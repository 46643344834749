.home4 {
  height: 100%;
    .bg {
      background-image: url(/assets/img/homepage/background6-mobile.jpg);
      background-position: center;
      background-size: cover;
      height:100%;
      text-align: center;

      @media (min-width: $sm-screen) {
        background-image: url(/assets/img/homepage/background6.jpg);
        background-position: center;
      }

      h1 {
        text-transform: uppercase;
        font-family: $bold;
        margin-top: 30%;
        
        @media (min-width: $sm-screen) {
          margin-top: 7%;
        }
      }
    }

    .bg1 {
      background-image: url(/assets/img/homepage/background5.jpg);
      background-position: center;
      background-size: cover;
      height:100%;

      @media (min-width: $sm-screen) {
        background-position: center;
      }
    }

    .discount {
      font-family: $bold;
      margin-top: 0;
      margin-bottom: 70px;
    }

    .benefits {
      background: #FFB563;
      color: white;
      padding: 10px 0;
      position: absolute;
      bottom: 0;
      width: 100%;

      .col-4 {
        text-align: center;
        img {
          text-align: center;
        }
      }
    }

    .wrapper,
    .container,
    .grid {
        height: 100%;
    }

    .button {
      background: #41CD8C;
      color: white;
      padding: 15px 70px;
      font-size: 18px;
    }

    p {
        color: white;
        font-size: 16px;
        letter-spacing: 1px;
        margin-top: 0;
        margin-bottom: 20px;
    }

    h1 {
        font-family: $regular;
        font-weight: normal;
        font-weight: normal;
        color: white;
        font-size: 30px;
        line-height: 3rem;
        margin-top: 40%;
        margin-bottom: 15px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.32);

        @media (min-width: $xs-screen) {
          font-size: 30px;
          line-height: 3rem;
          margin-top: 20%;
        }

        @media (min-width: $md-screen) {
          font-size: 50px;
          line-height: 4rem;
          margin-top: 12%;
        }
    }

    .logo {
        width: 55px;
        margin-top: 20px;

        @media (min-width: $sm-screen) {
            width: 50px;
        }
    }

    .header {
        position: fixed;
        width: 100%;
        z-index: 2;
    }

    .wrapper {
        position: relative;
        width: 100%;
        height: 600px;

        .rating {
          margin-top: 60px;

          img {
            width: 100%;

            @media (min-width: $sm-screen) {
              width: 40%;
            }
          }
        }
    }

    .text-left {
      text-align: left;
    }

    .text-right {
      text-align: right;
    }

    .content {
      h1 {
        font-family: $bold;
        font-size: 25px;
        color: #2d2d2d;
        text-shadow: none;
        margin-top: 20px;
      }

      h2 {
        font-size: 20px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 18px;
        font-family: $bold;
        margin-bottom: 10px;
      }

      p {
        letter-spacing: 0px;
        font-size: 15px;
        color: #2d2d2d;
      }

      img {
        width: 100%;
      }
    }

    .intro,
    .data,
    .asanarebel,
    .works {
      text-align: center;
    }

    .asanarebel {
      .button-wrapper {
        width: 100%;
        text-align: center;
        margin-top: 50px;
        margin: auto;

        @media (min-width: $sm-screen) {
          width: 50%;
        }
      }
    }

    .intro {
      margin-bottom: 40px;
    }

    .data,
    .works {
      background: #F5F6F7;
      padding: 30px 0;
      margin-top: 40px;

      img {
        width: 100px;
      }
    }

    .works {
      img {
        width: 150px;
      }
    }
}
