.landingpage {
    .discount {
        font-family: $bold;
        margin-top: 0;
    }

    p {
        color: white;
        font-size: 16px;
        letter-spacing: 1px;
        margin-top: 0;
        margin-bottom: 20px;
    }

    h1 {
        font-family: $bold;
        font-weight: 800;
        color: white;
        font-size: 30px;
        line-height: 3rem;
        margin-top: 50%;
        margin-bottom: 15px;

        @media (min-width: $sm-screen) {
            font-size: 45px;
            margin-top: 15%;
        }

        @media (min-width: $md-screen) {
            font-size: 45px;
            margin-top: 15%;
        }
    }

    .logo {
        width: 40px;
        margin-top: 20px;

        @media (min-width: $sm-screen) {
            width: 50px;
        }
    }

    .header {
        position: absolute;
        width: 100%;
        z-index: 2;
    }

    .wrapper {
        position: relative;
        display: table;
        width: 100%;
        height: 100%;
        background: none;
        white-space: nowrap;
    }

    .female,
    .male,
    .female2 {
        position: relative;
        display: table-cell;
        width: 50%;
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: 1;

        &:hover {
            opacity: 0.9;
            cursor: pointer;

            .button {
                background: white;
                font-weight: 800;
                color: $primary-color;
            }
        }
    }

    .female2 {
      background-image: url(/assets/img/homepage/female2.jpg);
      background-position: 30%;

      .button {
        &__animation {
          -webkit-animation: fadein 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
          animation: fadein 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;

          @-webkit-keyframes fadein {
            0% {
              letter-spacing: -0.5em;
              -webkit-filter: blur(12px);
                      filter: blur(12px);
              opacity: 0;
            }
            100% {
              -webkit-filter: blur(0px);
                      filter: blur(0px);
              opacity: 1;
            }
          }
          @keyframes focus-in-expand {
            0% {
              letter-spacing: -0.5em;
              -webkit-filter: blur(12px);
                      filter: blur(12px);
              opacity: 0;
            }
            100% {
              -webkit-filter: blur(0px);
                      filter: blur(0px);
              opacity: 1;
            }
          }
        }
      }
    }       
          
    
    .female {
        background-image: url(/assets/img/homepage/female.jpg);

        .button {
            &__animation {
                -webkit-animation: fadein 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
                animation: fadein 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
    
                @-webkit-keyframes fadein {
                    0% {
                      letter-spacing: -0.5em;
                      -webkit-filter: blur(12px);
                              filter: blur(12px);
                      opacity: 0;
                    }
                    100% {
                      -webkit-filter: blur(0px);
                              filter: blur(0px);
                      opacity: 1;
                    }
                  }
                @keyframes focus-in-expand {
                    0% {
                      letter-spacing: -0.5em;
                      -webkit-filter: blur(12px);
                              filter: blur(12px);
                      opacity: 0;
                    }
                    100% {
                      -webkit-filter: blur(0px);
                              filter: blur(0px);
                      opacity: 1;
                    }
                }              
            }
        }
    }

    .male {
        background-image: url(/assets/img/homepage/male.jpg);

        .button {
            &__animation {
                -webkit-animation: fadein 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2.5s both;
                animation: fadein 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2.5s both;
    
                @-webkit-keyframes fadein {
                    0% {
                      letter-spacing: -0.5em;
                      -webkit-filter: blur(12px);
                              filter: blur(12px);
                      opacity: 0;
                    }
                    100% {
                      -webkit-filter: blur(0px);
                              filter: blur(0px);
                      opacity: 1;
                    }
                  }
                @keyframes focus-in-expand {
                    0% {
                      letter-spacing: -0.5em;
                      -webkit-filter: blur(12px);
                              filter: blur(12px);
                      opacity: 0;
                    }
                    100% {
                      -webkit-filter: blur(0px);
                              filter: blur(0px);
                      opacity: 1;
                    }
                }              
            }
        }
    }

    .title {
        position: absolute;
        top: 10%;
        left: 0;
        right: 0;
        width: fit-content;
        text-align: center;
        margin: 0 auto;
        z-index: 2;

        h1 {
            font-family: $bold;
            font-size: 40px;
            line-height: 40px;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: white;

            @media (min-width: $sm-screen) {
                font-size: 75px;
                line-height: 75px;
            }
        }

        &__animation {
            -webkit-animation: fadein 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
	        animation: fadein 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;

            @-webkit-keyframes fadein {
                0% {
                  letter-spacing: -0.5em;
                  -webkit-filter: blur(12px);
                          filter: blur(12px);
                  opacity: 0;
                }
                100% {
                  -webkit-filter: blur(0px);
                          filter: blur(0px);
                  opacity: 1;
                }
              }
            @keyframes focus-in-expand {
                0% {
                  letter-spacing: -0.5em;
                  -webkit-filter: blur(12px);
                          filter: blur(12px);
                  opacity: 0;
                }
                100% {
                  -webkit-filter: blur(0px);
                          filter: blur(0px);
                  opacity: 1;
                }
            }              
        }
        
        &__fullcolor {
            -webkit-text-fill-color: white;
        }
    }
}

.button-mobile {
  border-top: 1px solid #F4F4F4;
  width: 100%;
  position: fixed;
  background: white;
  bottom: 0;
  padding: 30px 0;

  a {
    display: block;
    width: 100%;
  }

  .button {
    width: 50%;
  }

  @media (min-width: $sm-screen) {
    display: none;
  }
}