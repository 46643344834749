.button {
    display: inherit;
    width: 100%;
    color: white;
    background-image: linear-gradient(180deg, #83E353 0%, #23DD83 100%);
    border-radius: 26.5px;
    padding: 15px 0;
    text-shadow: 0 2px 4px rgba(0,0,0,0.20);
    text-transform: capitalize;
    text-align: center;
    margin: auto;

    @media (min-width: $md-screen) {
        width: 18%;
        margin: inherit;
    }

    &__transparent {
        width: 50%;
        position: absolute;
        bottom: 10%;
        left: 0;
        right: 0;
        border: 1px solid white;
        background: transparent;
        margin: auto;
        padding: 10px;
    }
}



