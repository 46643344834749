// Reflex is a flexbox grid which provides a way to take advantage of emerging
// flexbox support while providing a fall back to inline-block on older browsers
//
// To disable legacy support and build a flexbox only grid, set the variable
// $legacy-support in includes/variables to false
//
// Built by Lee Jordan
// email: ldjordan@gmail.com
// github: https://github.com/leejordan
//
// Structure and calculations are inspired by twitter bootstrap

@import 'includes/variables';
@import 'includes/mixins';
@import 'includes/grid';
@import 'includes/helpers';

