.influencer {
  text-align: center;

  .discount {
      color: #02D185;
      font-size: 20px;
      font-family: $bold;
      margin-top: 0;
  }

  .img-influencer {
    width: 100%;
  }

  p {
      color: $primary-color;
      font-size: 16px;
      letter-spacing: 1px;
      margin-top: 0;
      margin-bottom: 20px;
  }

  h2 {
    font-size: 25px;
  }

  .logo {
      width: 40px;
      margin-top: 20px;

      @media (min-width: $sm-screen) {
          width: 50px;
      }
  }

  .title {
    margin-top: 10px;
    margin-bottom: 150px;
    h1 {
      font-family: $bold;
      font-size: 40px;
      line-height: 50px;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $primary-color;

      @media (min-width: $sm-screen) {
          font-size: 75px;
          line-height: 80px;
      }
    }

    &__fullcolor {
      -webkit-text-fill-color: $primary-color;
    }

    @media (min-width: $sm-screen) {
      margin-top: 50px;
      margin-bottom: 0;
    }
  }

  .button {
    width: 100%;
    background: #02D185;
    color: white;
    margin-bottom: 50px;
  }

  .button-link {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 60%;
    margin: auto;
    margin-top: 40px;
    z-index: 2;

    @media (min-width: $sm-screen) {
      position: static;
    }
  }

  .header {
      background: white;
      width: 100%;
      z-index: 2;

      .logo {
        margin: auto !important;
        text-align: center !important;
      }
  }
}