.footer {
    width: 100%;
    background:white;
    padding-top: 50px;
    padding-bottom: 30px;
    margin: 0;

    h4 {
        font-family: $bold;
    }

    a {
        font-size: 18px;
        display: block;
        font-family: $regular;
        color: $footer-color;
        margin-top: 8px;
    }

    .imprint {
        border-top: 1px solid #F3F3F3;
        color: $footer-color;
        font-size: 15px;
        padding-top: 20px;

        span {
            margin-right: 20px;
        }

        a {
            font-size: 15px;
            display: inline-block;
            margin-right: 20px;
            &:hover {
                color: $primary-color;
            }
        }

        .languages {
            margin-bottom: 20px;
            a {
                font-size: 18px;
            }
        }
    }
}
